import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { AboutPage } from "./TempInfo";
import darkModeIcon from "./icons/dark_mode_FILL0_wght400_GRAD0_opsz48.svg";
import lightModeIcon from "./icons/light_mode_FILL0_wght400_GRAD0_opsz48.svg";

function App() {
  const [theme, setTheme] = useState(getInitialTheme);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((theme) => (theme === "light" ? "dark" : "light"));
  };

  function getInitialTheme() {
    const hour = new Date().getHours();
    return hour < 6 || hour >= 18 ? "dark" : "light";
  }

  useEffect(() => {
    const id = setInterval(() => {
      setTheme(getInitialTheme());
    }, 1000 * 60 * 60); // check every hour

    return () => clearInterval(id);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "var(--color-background-bg)",
        color: "var(--color-text)",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          padding: "10px",
          maxWidth: "600px",
          backgroundColor: "var(--color-background)",
        }}
      >
        {/* <header style={{ backgroundColor: "#282c34", color: "white" }}>
        excuse the neglect below
      </header> */}
        <span style={{ cursor: "pointer", display: "flex", justifyContent: "flex-end" }}>
          <img
            src={theme === "light" ? lightModeIcon : darkModeIcon}
            onClick={toggleTheme}
          />
        </span>
        <AboutPage />
      </div>
    </div>
  );
}

export default App;
