import React from "react";
import { CopyToClipboard } from "./CopyToClipboard";

const iconHeight = 100;
export const AboutPage: React.FC = () => (
  <div
    style={{
      fontFamily: "Instrument Sans, sans-serif",
      padding: "10px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        alt="sterv-icon"
        src={"./sterv-icon.jpeg"}
        style={{
          marginTop: "1em",
          width: iconHeight,
          height: iconHeight,
          borderRadius: "50%",
        }}
      />
      <h1 style={{ textAlign: "center" }}>sterv.com</h1>
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        Music Software Apps
        <span
          style={{
            margin: "0 5px",
            fontWeight: "bold",
            color: "var(--color-background-bg)",
          }}
        >
          /
        </span>
        Generative A.I.
        <span
          style={{
            margin: "0 5px",
            fontWeight: "bold",
            color: "var(--color-background-bg)",
          }}
        >
          /
        </span>
        Programmatic Media
      </p>

      <span style={{ textAlign: "center", marginTop: ".5em" }}>Miami, FL</span>
    </div>
    <hr />
    <h3>Work</h3>I spend most of my time on Middy.com. I'm also open to
    freelance work and consulting.
    <p>
      <strong>Current</strong>
      <br />
      <a href="https://www.middy.com">Middy.com</a> - Bridging the gap between
      music creation and music education, Founder & Principal Engineer, 6+ yrs
    </p>
    <p>
      <strong>Past</strong>
      <br />
      <a href="https://www.represent.com">Represent.com</a> - Crowdfunded custom
      apparel, Acquired in 2018, Director of Growth, 2+ yrs
      {/* <br /> */}
      {/* <del>Undecided.com</del> - Video social network, R.I.P. 2015 ⚰️, Product &
      Marketing, 1+ yrs */}
    </p>
    <hr />
    <h3>Projects</h3>
    <p>
      <a href="https://www.youtube.com/@AIFutureOfMusic">
        AI + the Future of Music Podcast
      </a>{" "}
      - Discussions about AI and the future of music, Producer & Participate
    </p>
    <hr />
    <h3>Personal Achievement</h3>
    <p>Hall of Honor Inductee at Bowdoin College in 2023</p>
    <p>NCAA D3 Tennis Doubles Champion in 2011</p>
    <p> NCAA D3 Tennis Singles Semi-Finalist in 2009</p>
    <p>6x NCAA D3 Tennis All-American from 2008 to 2011</p>
    <hr />
    <h3>Education</h3>
    <p>Full-stack software engineer, Makersquare in 2015</p>
    <p>Music production / Audio Engineering, Icon Collective in 2013</p>
    <p>B.A. Economics, Bowdoin College in 2011</p>
    <hr />
    <div>
      Twitter: <a href="https://twitter.com/stervdotcom">@stervdotcom</a>
      <br />
      GitHub: <a href="https://github.com/stervdotcom">@stervdotcom</a>
      <br />
      Instagram: <a href="https://instagram.com/stervdotcom">@stervdotcom</a>
      <br />
      Email: <CopyToClipboard text="stephen@middy.com" />
    </div>
  </div>
);
