import React, { useEffect, useState } from "react";

export const CopyToClipboard: React.FC<{ text: string }> = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isCopied) {
      timeoutId = setTimeout(() => setIsCopied(false), 3000); // 3 seconds
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isCopied]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <span
      
      onClick={copyToClipboard}
      style={{
        color: isCopied ? "var(--color-used-link)" : "var(--color-link)",
        // textDecoration: " ,
        cursor: "pointer",
      }}
    >
      {isCopied ? "Copied!" : text}
    </span>
  );
};
